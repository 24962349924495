import { getUrlStyle } from '@chegg-wt/core/server/utils/redirect'
import { AppRouteCreators } from '@chegg-wt/core/types/core'

/**
 * These represent the major points in our application
 *
 * { href } -> indicates a link that will be handled by the browser, whether external or not
 * { href, as } -> indicates a link that will be handled by NextJS via it's SPA routing mechanisms
 */
export const routes: AppRouteCreators = {
  toCitationsHome: () => ({ href: '/' }),
  toProject: () => ({ href: '/project', as: '/project' }),
  toProfile: () => ({ href: '/myaccount', as: '/myaccount' }),
  toLogout: () => ({ href: '/logout', as: '/logout' }),
  /**
   * Returns WebRoute because there are issues with chegg auth
   */
  toUpgrade: () => ({ href: '/upgrade' }),
  toUpgradeCTA: () => ({ href: '/upgrade' }),
  /**
   * Returns WebRoute because there are issues with chegg auth
   */
  toSignup: () => ({ href: '/register' }),
  /**
   * Returns WebRoute because there are issues with chegg auth
   */
  toLogin: () => ({ href: '/login' }),
  toPlagiarism: () => ({ href: '/grammar-and-plagiarism/' }),
  toTermsAndConditions: () => ({ href: '/terms-and-conditions' }),
  toGateway: () => ({ href: '/gateway', as: '/gateway' }),
  toSourceTypeSelection: ({ style }) => ({
    href: '/[style]/source',
    as: `/${getUrlStyle(style)}/source`,
  }),
  toSourceSearch: ({ style, sourceType }) => ({
    href: '/[style]/[source]/search-form',
    as: `/${getUrlStyle(style)}/${sourceType}/search-form`,
  }),
  toSourceSelection: ({ style, sourceType, query }) => ({
    href: `/[style]/[source]/search?q=${query}`,
    as: `/${getUrlStyle(style)}/${sourceType}-citation/search?q=${query}`,
  }),
  toSourceConfirmation: ({ style, sourceType }) => ({
    href: '/[style]/[source]/eval',
    as: `/${getUrlStyle(style)}/${sourceType}-citation/eval`,
  }),
  toNewCitation: ({ style, sourceType }) => ({
    href: '/[style]/[source]/new',
    as: `/${getUrlStyle(style)}-format/${sourceType}-citation/new`,
  }),
  toManualCitation: ({ style, sourceType }) => ({
    href: '/[style]/[source]/custom',
    as: `/${getUrlStyle(style)}-format/${sourceType}-citation/custom`,
  }),
  toEditCitation: ({ style, sourceType }) => ({
    href: '/[style]/[source]/edit',
    as: `/${getUrlStyle(style)}-format/${sourceType}-citation/edit`,
  }),
  toBibliography: ({ style, projectId }) => ({
    href: `/project/style/[style]?id=${projectId}`,
    as: `/project/style/${getUrlStyle(style)}?id=${projectId}`,
  }),
  toFolders: ({ create } = {}) => {
    if (create)
      return { href: `/folders?create=${create}`, as: `/folders?create=${create}` }
    return { href: '/folders', as: '/folders' }
  },
  toDashboard: () => ({ href: '/dashboard', as: '/dashboard' }),
  toCitationsDashboard: () => ({
    href: '/does/not/exist',
  }),
  toCitationList: ({ projectId }) => ({
    href: '/citations/[id]',
    as: `/citations/${projectId}`,
  }),
  toCitationListSource: ({ projectId }) => ({
    href: `/citations/[id]?id=${projectId}&step=source`,
    as: `/citations/${projectId}/source`,
  }),
  toCitationListForm: ({ projectId, sourceType }) => {
    return {
      href: `/citations/[id]?id=${projectId}&step=form${
        sourceType ? `&sourceType=${sourceType}` : ''
      }`,
      as: `/citations/${projectId}/form`,
    }
  },
  toUploadLp: () => ({ href: '/does/not/exist' }),
  toPapersDashboard: () => ({
    href: '/does/not/exist',
  }),
  toError: () => ({
    href: '/does/not/exist',
  }),
  toWritingAssistant: ({ paperId } = {}) => {
    if (paperId)
      return { href: `/writing-assistant/[id]`, as: `/writing-assistant/${paperId}` }
    return { href: '/writing-assistant', as: '/writing-assistant' }
  },
}
