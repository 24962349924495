import { colors } from '@chegg-ui/theme'
import { adFrameTheme } from '@chegg-wt/core/theme/theme'

/**
 * https://app.zeplin.io/project/5b71e2295bde783107ce2133/screen/5b71e26713ddc827cc6f46c9
 * https://app.zeplin.io/project/5b71e2295bde783107ce2133/screen/5b71e267277f0d5b80d84c58
 * https://app.zeplin.io/project/5b71e2295bde783107ce2133/screen/5b71e267770851120d432eb7
 *
 * Color Units are defined in above documents:
 *    interface-color-xx
 *    color-error
 *    f-color-xx
 *
 * Categories of colors in "Interface Color Palette" document:
 *    Primary Color
 *    Secondary Colors
 *    Functional Colors
 *
 * Further Functional Colors can be gleaned from the "Forms" and "Alerts" documents
 */

/**
 * Interface (background, border, etc) Colors
 */
const forInterface = {
  interfaceColor01: colors.cheggOrange,
  interfaceColor02: '#ffb70d',
  interfaceColor03: '#4dc66d',
  interfaceColor04: '#da578a',
  interfaceColor05: '#5c90c7',
  interfaceColor06: '#21a29d',
  interfaceColor07: '#fff5c6',
  interfaceColor08: '#defce3',
  interfaceColor09: '#fff2ed',
  interfaceColor10: '#eef5ff',
  interfaceColor11: colors.white,
  interfaceColor12: '#eeeeee',
  interfaceColor13: '#e5e5e5',
  interfaceColor14: colors.greyMedlight,
  interfaceColor15: colors.grey,
  interfaceColor16: '#555555',
  interfaceColor17: '#333333',
  interfaceColor18: '#e0faf5',
  interfaceColor19:
    colors.greyXlight /* possibly also interfaceColor20 via https://trunk.live.test.cheggnet.com/componentlibrary > Interface Colors > Background */,
  interfaceColor20: '#f8f8f8',
  interfaceColor21: '#dddddd',
  interfaceColor22: colors.greyMedium,
  interfaceColor23: '#d46e00',
  interfaceColor24: 'rgba(237, 113, 0, 0.2)',
  interfaceColor25: '#188e87',
  interfaceColor26: '#229d9e',
}
/**
 * Font colors (link, button text, headings, emphasis, etc)
 */

const forFonts = {
  fColor01: colors.white,
  fColor02: '#dddddd',
  fColor03: colors.grey,
  fColor04: '#555555',
  fColor05: '#333333',
  fColor06: '#000000',
  fColor07: '#ffb70d',
  fColor08: colors.cheggOrange,
  fColor09: '#d3332a',
  fColor10: '#008155',
  fColor11: '#21a29d',
  fColor12: '#007c8a',
  fColor13: '#5c90c7', // NOTE: from https://trunk.live.test.cheggnet.com/componentlibrary
  fColor14: '#007c8a', // NOTE: repeat of fColor12, from https://trunk.live.test.cheggnet.com/componentlibrary
  fColor15: '#4a807a',
  fColor16: '#c45400',
  fColor17: '#008f88',
  fColor18: '#4a4a4a',
  fColor19: colors.dark,
}

export const EBTheme = {
  ...adFrameTheme,
  font: {
    ...adFrameTheme.font,
    family:
      "'Aspira Webfont', 'Aspira', 'Helvetica', 'Arial', 'Arimo', 'Liberation Sans', sans-serif",
  },
  Colors: {
    forInterface,
    primaryColor: forInterface.interfaceColor01,
    colorInformation: forInterface.interfaceColor05,
    colorSuccess: forInterface.interfaceColor03,
    colorError: '#d3332a' /* not specified as an interfaceColorXX */,
    colorImportant: '#ffcc00' /* not specified as an interfaceColorXX */,
    forFonts,
    /**
     * [1]: Design notified me that Sunkist doesn't specify hover colors,
     *      but that we should specify them based on the active color
     */
    linkColor: forFonts.fColor12,
    visitedLinkColor: forFonts.fColor15,
    hoverLinkColor: forFonts.fColor12 /* [1] */,
    activeLinkColor: forFonts.fColor12,
    /**
     * [1]: Design notified me that Sunkist doesn't specify hover colors,
     *      but that we should specify them based on the active color
     */
    secondaryLinkColor: forFonts.fColor04,
    visitedSecondaryLinkColor: forFonts.fColor03,
    hoverSecondaryLinkColor: forFonts.fColor04 /* [1] */,
    activeSecondaryLinkColor: forFonts.fColor04,
    textColor: forFonts.fColor05,
    textColorGray: forFonts.fColor04,
    textColorLightGray: forFonts.fColor03,
    bottomContent: '#f8f8f8',
    resultLink: forFonts.fColor08,
  },
}
