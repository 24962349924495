import { supportPageLinks } from '@chegg-wt/core/constants/supportPageLinks'
import { Config } from '@chegg-wt/core/config'
import { FooterColumn } from '@chegg-wt/core/types/core'

export const footerLinkColumns: FooterColumn[] = [
  {
    title: 'Company',
    links: [
      {
        name: 'About Us',
        url: `${Config.baseUrl}/guides/company/`,
      },
      { name: 'Blog', url: `${Config.baseUrl}/guides/blog/` },
      { name: 'Chegg Inc.', url: Config.cheggEnvUrl },
      { name: 'Contact Us', url: supportPageLinks.easybib },
      { name: 'Support', url: supportPageLinks.easybib },
    ],
  },
  {
    title: 'Resources',
    links: [
      {
        name: 'EasyBib® Plus',
        url: `${Config.baseUrl}/upgrade`,
      },
      {
        name: 'Citation Guides',
        url: `${Config.baseUrl}/guides/`,
      },
      {
        name: 'APA Format',
        url: `${Config.baseUrl}/guides/citation-guides/apa-format/`,
      },
      {
        name: 'MLA Format',
        url: `${Config.baseUrl}/guides/citation-guides/mla-format/`,
      },
      {
        name: 'Chicago Style Format',
        url: `${Config.baseUrl}/guides/citation-guides/chicago-turabian/`,
      },
    ],
  },
  {
    title: 'Follow Us',
    links: [
      { name: 'Facebook', url: 'https://facebook.com/easybib', rel: 'nofollow' },
      { name: 'Twitter', url: 'https://twitter.com/EasyBib', rel: 'nofollow' },
      {
        name: 'Youtube',
        url: 'https://www.youtube.com/playlist?list=PLllCkartRRdM3dvcs7xlFS7J_2OeyTDJq',
        rel: 'nofollow',
      },
    ],
  },
  {
    title: 'Business',
    links: [
      { name: 'Terms of Use', url: `${Config.cheggEnvUrl}/termsofuse` },
      { name: 'Global Privacy Policy', url: `${Config.cheggEnvUrl}/privacypolicy` },
      { name: 'Cookie Notice', url: `${Config.cheggEnvUrl}/cookienotice/` },
      {
        name: 'DO NOT SELL MY INFO',
        id: 'ot-sdk-btn',
        className: 'ot-sdk-show-settings',
        url: '#',
      },
    ],
  },
]
