import { createSelector } from 'reselect'
import { AppState } from '@chegg-wt/core/state'
import { Config } from '@chegg-wt/core/config'
import {
  MY_CITATION_PROJECT_LIST_TITLE,
  NEW_CITATION_LIST_BUTTON,
} from '@chegg-wt/core/constants/citations'
import {
  DASHBOARD,
  CITATIONS,
  PAPERS,
  GUIDES,
  PLAGIARISM,
} from '@chegg-wt/core/constants/nav'
import { NavItem, NavItems } from '@chegg-wt/core/types/core'
import { currentProjectIdSelector } from '@chegg-wt/core/state/currentProject/selectors'
import { userEmailSelector, isProSelector } from '@chegg-wt/core/state/user/selectors'
import { supportPageLinks } from '@chegg-wt/core/constants/supportPageLinks'
import { isWritingAssistantHomeFlagEnabledSelector } from '@chegg-wt/core/state/paraphraser/selectors'

export const navItemsSelector: (state: AppState) => NavItems = createSelector(
  [
    isProSelector,
    userEmailSelector,
    currentProjectIdSelector,
    isWritingAssistantHomeFlagEnabledSelector,
    (_state: AppState, isEBHome?: boolean) => isEBHome,
  ],
  (
    paidUser: boolean,
    email: string,
    currentProjectId: string,
    isWritingAssistantHomeEnabled: boolean,
    isEBHome?: boolean
  ) => {
    const isWritingAssistEnabledAndHomePage: boolean =
      isWritingAssistantHomeEnabled && isEBHome

    const main: NavItem[] = [
      {
        name: 'Plagiarism and grammar',
        icon: 'citation-guides',
        href: '/grammar-and-plagiarism/',
      },
      {
        name: 'School access',
        icon: 'plus',
        href: '/guides/miss-easybib-for-schools-then-this-is-for-you/',
      },
      {
        name: 'About us',
        icon: 'about-us',
        href: '/guides/company/',
      },
      {
        name: 'Support',
        icon: 'support',
        href: `${supportPageLinks.easybib}/?Website=External`,
      },
    ]

    let right: NavItem[] = []

    const account: NavItem[] = [
      {
        name: MY_CITATION_PROJECT_LIST_TITLE,
        icon: 'projects',
        route: 'toFolders',
      },
      {
        name: NEW_CITATION_LIST_BUTTON,
        icon: 'add',
        route: 'toFolders',
        query: {
          create: 'project',
        },
      },
      {
        name: 'Settings',
        icon: 'settings',
        route: 'toProfile',
      },
      {
        name: 'Logout',
        icon: 'logout',
        route: 'toLogout',
      },
    ]

    const getCitationNavItem = (): NavItem => {
      const citationsItem: NavItem = {
        name: CITATIONS,
        hideOnSmall: true,
      }

      if (currentProjectId) {
        return {
          ...citationsItem,
          route: 'toProject',
        }
      }
      if (email) {
        return {
          ...citationsItem,
          route: 'toFolders',
        }
      }
      return {
        ...citationsItem,
        route: 'toSourceTypeSelection',
        query: {
          style: 'mla9',
        },
      }
    }

    const getPapersNavItem = (): NavItem => {
      const papersItem: NavItem = {
        name: PAPERS,
        hideOnSmall: true,
      }

      if (email) {
        return {
          ...papersItem,
          href: Config.writingUrl,
        }
      }

      return {
        ...papersItem,
        href: Config.grammarAndPlagiarismUrl,
      }
    }

    const getPlagiarismNavItem = (): NavItem => {
      const plagiarismItem: NavItem = {
        name: PLAGIARISM,
        route: 'toPlagiarism',
        hideOnMobile: true,
      }
      return plagiarismItem
    }

    const header: NavItem[] = [
      {
        name: DASHBOARD,
        route: 'toDashboard',
        hideOnSmall: true,
      },
      getCitationNavItem(),
      isWritingAssistEnabledAndHomePage ? getPlagiarismNavItem() : getPapersNavItem(),
      {
        name: GUIDES,
        href: Config.guidesUrl,
        hideOnSmall: true,
      },
    ]

    if (!paidUser) {
      main.splice(3, 0, {
        name: 'Upgrade',
        icon: 'plus',
        route: 'toUpgrade',
      })

      right = [
        ...right,
        {
          name: 'Upgrade',
          route: 'toUpgrade',
          hideOnMobile: true,
        },
      ]

      account.splice(3, 0, {
        name: 'Upgrade',
        icon: 'plus',
        route: 'toUpgrade',
      })
    }

    if (!email) {
      right = [
        ...right,
        {
          name: 'Sign up',
          route: 'toSignup',
        },
        {
          name: 'Sign in',
          route: 'toLogin',
        },
      ]
    }

    const getWritingAssistantMain = (): NavItem[] => {
      if (!paidUser)
        return [
          {
            name: 'Upgrade',
            route: 'toUpgrade',
          },
        ]
      return []
    }

    return {
      main: isWritingAssistEnabledAndHomePage ? getWritingAssistantMain() : main,
      right,
      account,
      header,
    }
  }
)
